
import { defineComponent, ref } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Api from './Api'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'

export default defineComponent({
    name: "EditRate",
    components: {Footer, NavBar},
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const id = route.params.reference
        const networks = ref<any>([])
        let adminEmail = ref<any>('')

        const getAdminEmail = () => {
            adminEmail.value =  sessionStorage.getItem('email')
        }

        const coin = ref({
            coin_id: 0 as number,
            coin_name:'' as string,
            coin_description: '' as string,
            buy_rate: 0 as number,
            sell_rate: 0 as number,
            coin_status: false as boolean,
            minimum_buy_limit: 0 as number,
            minimum_sell_limit: 0 as number,
            maximum_buy_limit: 0 as number,
            maximum_sell_limit: 0 as number,
            coin_short_code: '' as string,
            coin_sell_status: false as boolean,
            coin_buy_status: false as boolean,
            first_address: '' as string,
            second_address: '' as string,
            third_address: '' as string,
            fourth_address: '' as string,
            fifth_address: '' as string,
            has_networks: false as boolean,
            all_address: "" as string,
            confirmation_fee: '' as string
        })
        const network = ref({
            coin_id: 0 as number,
            network_name: '' as string,
            first_address: '' as string,
            second_address: '' as string,
            third_address: '' as string,
        })
        const fillDetails = () => {
            const allCoin = ref<any>(store.state.all_coin)
            const selectedCoin = allCoin.value.filter((coin:any) => coin.unique_id == id)
            network.value.coin_id = selectedCoin[0].id
            coin.value.coin_id = selectedCoin[0].id
            coin.value.coin_description = selectedCoin[0].coin_description
            coin.value.coin_name = selectedCoin[0].coin_name
            coin.value.buy_rate = selectedCoin[0].buy_rate
            coin.value.sell_rate = selectedCoin[0].sell_rate
            coin.value.coin_status = selectedCoin[0].coin_status
            coin.value.minimum_buy_limit = selectedCoin[0].minimum_buy_limit
            coin.value.maximum_buy_limit = selectedCoin[0].maximum_buy_limit
            coin.value.minimum_sell_limit = selectedCoin[0].minimum_sell_limit
            coin.value.maximum_sell_limit = selectedCoin[0].maximum_sell_limit
            coin.value.coin_short_code = selectedCoin[0].coin_short_code
            coin.value.coin_sell_status = selectedCoin[0].sell_active_status
            coin.value.coin_buy_status = selectedCoin[0].buy_active_status
            coin.value.first_address = selectedCoin[0].first_address
            coin.value.second_address = selectedCoin[0].second_address
            coin.value.third_address = selectedCoin[0].third_address
            coin.value.fourth_address = selectedCoin[0].fourth_address
            coin.value.fifth_address = selectedCoin[0].fifth_address
            coin.value.has_networks = selectedCoin[0].has_networks
            coin.value.confirmation_fee = selectedCoin[0].confirmation_fee
        }
        const updateCoin = async (e:any) => {
            e.preventDefault()
            if (coin.value.all_address !== "null"){
                let items:string[] = [];
                coin.value.all_address.split(",").map((item:string) => {
                    items.push(item.trim())
                    if(items.length == 1){
                       coin.value.first_address = items[0] 
                    }else if(items.length == 2){
                        coin.value.second_address = items[1]
                    }else if(items.length == 3){
                        coin.value.third_address = items[2]
                    }else if(items.length == 4){
                        coin.value.fourth_address = items[3]
                    }else if(items.length == 5){
                        coin.value.fifth_address = items[4]
                    }
                })
            }
            const formData = {
                coin_name: coin.value.coin_name,
                coin_description: coin.value.coin_description,
                buy_rate: coin.value.buy_rate,
                sell_rate: coin.value.sell_rate,
                coin_status: coin.value.coin_status,
                minimum_buy_limit: coin.value.minimum_buy_limit,
                maximum_buy_limit: coin.value.maximum_buy_limit,
                minimum_sell_limit: coin.value.minimum_sell_limit,
                maximum_sell_limit: coin.value.maximum_sell_limit,
                short_code: coin.value.coin_short_code,
                buy_active_status: coin.value.coin_buy_status,
                sell_active_status: coin.value.coin_sell_status,
                first_address: coin.value.first_address,
                second_address: coin.value.second_address,
                third_address: coin.value.third_address,
                fourth_address: coin.value.fourth_address,
                fifth_address: coin.value.fifth_address,
                has_networks: coin.value.has_networks,
                confirmation_fee: coin.value.confirmation_fee
            }
            try {
                await Api.axios_instance.patch(Api.baseUrl+('api/v1/update-coin/'+id), formData)
                .then(res => {
                    router.push({path:'/rates'})
                   alert("Rate Has Been Updated")
                })
            }catch(e){
               alert("An error occured, pleae contact admin")
            }
            updateNetwork()
        }
        const getNetwork = async () => {
            Api.axios_instance.get(Api.baseUrl+'api/v1/list-networks/'+network.value.coin_id)
            .then(res => {
                networks.value = res.data
            })
        }
        const updateNetwork = async () => {
            const formData = {
                network_name: network.value.network_name,
                first_address: network.value.first_address,
                second_address: network.value.second_address,
                third_address: network.value.third_address
            }
            Api.axios_instance.patch(Api.baseUrl+'api/v1/update-network/'+network.value.coin_id, formData)
            .then(res => {
                console.log(res.data); 
            })
        }

        onMounted(() => {
            fillDetails();
            getNetwork();
            getAdminEmail()
        })
        return {coin, updateCoin, updateNetwork, network, getNetwork, networks, adminEmail}
    },
})
