
import { defineComponent,ref,onMounted, onBeforeUnmount, computed } from 'vue'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import { useRoute , useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Api from '../views/Api'
import axios from 'axios'

export default defineComponent({
    name: "TradeUpdate",
    components: {Footer, NavBar},
    setup() {
        const route = useRoute()
        const store = useStore()
        const router = useRouter()
        const tradeDetails = ref({
            coin: '' as string,
            coin_address: '' as string,
            first_name: '' as string,
            last_name: '' as string,
            coin_amount: 0 as number,
            naira_amount: 0 as number,
            dollar_amount: 0 as number,
            transaction_status:0 as number,
            hash_key: '' as string,
            comment: 'No Comment' as string,
            member_id: '' as string,
            trade_type: '' as string,
            amount_received: 0 as number,
            paid_dollar_amount: 0 as number,
            paid_naira_amount: 0 as number,
            hash_key_type: '2' as string,
            editable: false as boolean,
            pm_account: '' as string,
            campaign_bonus: false as boolean,
            bonus_bank: {},
            updating: false as boolean
        })
        const reference = ref<any>("")
        const expiration_time = ref<any>('')
        const expiration_wallet = ref<boolean>(false)
       let transaction_time = ref<any>()
        const bankDetails = ref({
            bank_name: '' as string,
            account_number: '' as string,
            account_name: '' as string
        })
        const bonusBankDetails = ref({
            bank_name: '' as string,
            account_number: '' as string,
            account_name: '' as string
        })
       let timeRemaining = ref<number>(0) // in seconds
        let intervalId = ref<any>(null)
        
        const setTradeValues = () => {
            const transactions = ref<any>(store.state.all_transactions)
            const selected = transactions.value.filter((transaction:any) => transaction.transaction_reference == reference.value)
            
            tradeDetails.value.coin = selected[0].coin.coin_name
            tradeDetails.value.coin_address = selected[0].coin_address

            tradeDetails.value.first_name = selected[0].user.first_name
            tradeDetails.value.last_name = selected[0].user.last_name
            tradeDetails.value.coin_amount = selected[0].coin_amount
            tradeDetails.value.naira_amount = selected[0].naira_amount
            tradeDetails.value.dollar_amount = selected[0].dollar_amount
            tradeDetails.value.hash_key = selected[0].hash_key
            tradeDetails.value.hash_key_type = selected[0].hash_key_type
            tradeDetails.value.comment = selected[0].comment
            tradeDetails.value.transaction_status = selected[0].transaction_status
            tradeDetails.value.member_id = selected[0].user.member_id
            tradeDetails.value.trade_type = selected[0].trade_type
            tradeDetails.value.pm_account = selected[0].pm_account
            tradeDetails.value.editable = selected[0].editable
            tradeDetails.value.campaign_bonus = selected[0].campaign_bonus
            tradeDetails.value.updating = selected[0].updating
            bonusBankDetails.value.bank_name = selected[0].bonus_bank.bank_name
            bonusBankDetails.value.account_number = selected[0].bonus_bank.account_number
            bonusBankDetails.value.account_name = selected[0].bonus_bank.account_name
            
            expiration_time.value = selected[0].expiration_time
            expiration_wallet.value = selected[0].expiration_wallet
            transaction_time.value = selected[0].date
            
            if (tradeDetails.value.trade_type == 'SELL' && tradeDetails.value.coin != "Perfect Money") {
                bankDetails.value.bank_name = selected[0].bank.bank_name
                bankDetails.value.account_name = selected[0].bank.account_name
                bankDetails.value.account_number = selected[0].bank.account_number
                tradeDetails.value.amount_received = selected[0].amount_received
                tradeDetails.value.paid_dollar_amount = selected[0].paid_dollar_amount
                tradeDetails.value.paid_naira_amount = selected[0].paid_naira_amount
            }
        }

        /* Update Trade Details */
        const updateTransaction = async () => {
            if(tradeDetails.value.transaction_status == 2 || tradeDetails.value.transaction_status == 4 
                || tradeDetails.value.transaction_status == 7){
                tradeDetails.value.editable = true 
            }else{
                tradeDetails.value.editable = false
            }
            const sellFormData = {
                coin_amount:tradeDetails.value.coin_amount,
                naira_amount:tradeDetails.value.naira_amount,
                dollar_amount: tradeDetails.value.dollar_amount,
                comment: tradeDetails.value.comment,
                transaction_status: tradeDetails.value.transaction_status,
                paid_dollar_amount: tradeDetails.value.paid_dollar_amount,
                paid_naira_amount: tradeDetails.value.paid_naira_amount,
                amount_received: tradeDetails.value.amount_received,
                hash_key: tradeDetails.value.hash_key,
                hash_key_type: tradeDetails.value.hash_key_type,
                editable: tradeDetails.value.editable
            }
            const buyFormData = {
                coin_amount:tradeDetails.value.coin_amount,
                naira_amount:tradeDetails.value.naira_amount,
                dollar_amount: tradeDetails.value.dollar_amount,
                comment: tradeDetails.value.comment,
                transaction_status: tradeDetails.value.transaction_status,
                hash_key: tradeDetails.value.hash_key,
                hash_key_type: tradeDetails.value.hash_key_type,
                editable: tradeDetails.value.editable
            }
            console.log(buyFormData);
            console.log(tradeDetails.value.trade_type);
            
             console.log(tradeDetails.value.transaction_status);
             
            try {
                if(tradeDetails.value.trade_type == 'SELL'){
                    await Api.axios_instance.patch(Api.baseUrl+'api/v1/approve-dissapprove-trade/'+route.params.reference, sellFormData)
                    .then(res => {
                        Api.axios_instance.get(Api.baseUrl+'api/v1/send_mail/'+route.params.reference)
                        router.push({path:'/'})
                        alert('Transaction Updated Successfully')
                    })
                    .catch(error => {
                        console.log(error);
                    })
                } else {
                        await Api.axios_instance.patch(Api.baseUrl+'api/v1/approve-dissapprove-trade/'+route.params.reference, buyFormData)
                        .then(res => {
                            Api.axios_instance.get(Api.baseUrl+'api/v1/send_mail/'+route.params.reference)
                            router.push({path:'/'})
                            alert('Transaction Updated Successfully')
                        })
                    
                   
                }
              
            }catch(e){
                alert('An error occured please contact admin') 
            } 
        }

        const disableEdit = async (val:boolean) => {
            setTimeout(() => {
                Api.axios_instance.patch(Api.baseUrl+'api/v1/approve-dissapprove-trade/'+reference.value, {updating:val})
            }, 3000)
        }

        const updateBtcTrade = () => {
            axios.get("https://blockchain.info/address/"+tradeDetails.value.coin_address+"?format=json")
            .then(res => {
                if(res?.data){
                    tradeDetails.value.hash_key = res.data.txs[0].hash
                    let btc_convert_rate = tradeDetails.value.dollar_amount / tradeDetails.value.coin_amount
                    let naira_rate = tradeDetails.value.naira_amount / tradeDetails.value.dollar_amount
                    let satoshi_btc = res.data.total_received / 100000000
                    let satoshi_usd = satoshi_btc * btc_convert_rate
                    let converted_naira_amount = satoshi_usd * naira_rate
                    tradeDetails.value.amount_received = satoshi_btc
                    tradeDetails.value.paid_dollar_amount = satoshi_usd
                    tradeDetails.value.paid_naira_amount = converted_naira_amount
                
                    let data = {
                        paid_dollar_amount: satoshi_usd,
                        paid_naira_amount: converted_naira_amount,
                        amount_received: satoshi_btc,
                        hash_key: tradeDetails.value.hash_key
                    }
                    Api.axios_instance.patch(Api.baseUrl+'api/v1/approve-dissapprove-trade/'+route.params.reference, data)
                }
               
            })
        } 
        

let formattedTime:any = computed(() => {
    const minutes = Math.floor(timeRemaining.value / 60);
    const seconds = Math.floor(timeRemaining.value % 60);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
});

const startCountdown = () => {
    if (!expiration_wallet.value && tradeDetails.value.transaction_status !== 1) return;

    const transactionTime = new Date(transaction_time.value).getTime() / 1000; // Convert ISO string to Unix timestamp in seconds
    const countdownDuration = expiration_time.value * 60; // Convert expiration_time to seconds
    timeRemaining.value = countdownDuration - (Date.now() / 1000 - transactionTime); // Calculate remaining time

    // Clear any existing intervals
    if (intervalId) {
        clearInterval(intervalId.value);
    }

    // Start a new interval
    intervalId.value = setInterval(() => {
            if(tradeDetails.value.transaction_status == 1){
            if (timeRemaining.value > 0) {
                timeRemaining.value = parseFloat((timeRemaining.value - 0.1).toFixed(1)); // decrement by 0.1 seconds for more granular control
            } else {
                // Perform actions when countdown reaches zero
                formattedTime = "Expired"
                console.log(formattedTime);
                
                Api.axios_instance.get(Api.baseUrl + "api/v1/send_expiry_mail/" + reference.value)
                    .then((res) => {
                        alert("Customer has been requested to send a new wallet address via mail ")
                    })
                clearInterval(intervalId.value);
            }
        }
    }, 100); // Adjust interval time for smoother countdown (100 ms)
};

        onBeforeUnmount(() => {disableEdit(false)})
        onMounted(() => {
            reference.value = route.params.reference
            setTradeValues()
            disableEdit(true)
            if (expiration_time.value !== undefined) {
                timeRemaining.value = parseFloat(expiration_time.value.toFixed(1));
             startCountdown()
            }
           updateBtcTrade()
        })

        return {setTradeValues, tradeDetails, updateTransaction, bankDetails,
            bonusBankDetails, expiration_time, expiration_wallet, startCountdown,formattedTime, timeRemaining }
      },
})
